import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
// components
import SEO from '../components/SEO';
import SectionTrigger from '../components/SectionTrigger';
// utils
import { above, colors, size } from '../utils';

const { black, blackHover, white, whiteHover } = colors;

const PageWrap = styled.section.attrs(props => ({
  bgColor: `${props.bgColor}`,
  textColor: `${props.textColor}`,
}))`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${above.huge`
    max-width: ${size.huge}px;
    margin: 0 auto;
  `}

  .project-title {
    padding: 0 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const PageLink = styled(Link)`
  color: ${({ textColor }) => textColor};
  font-size: 1vw;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 1.2;
  margin: 0 10px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.15s ease;

  ${above.huge`
    font-size: 16px;
  `}

  &:hover {
    color: ${({ textColor }) =>
      textColor === `${white}` ? `${whiteHover}` : `${blackHover}`};
  }
`;

function NotFoundPage() {
  return (
    <div>
      <SEO title="Page Not found | Falkon Content" />
      <PageWrap bgColor={white} textColor={black}>
        <div className="project-title">
          <h1 className="main-heading">Sorry!</h1>
          <p>We can&#39;t seem to find the page you are looking for.</p>
          <PageLink color={black} to="/">
            Take Me Back Home
          </PageLink>
        </div>
      </PageWrap>
    </div>
  );
}

export default NotFoundPage;
